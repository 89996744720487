import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalobjectsService } from "../../globalobjects.service";
import { LoginService } from "../loginc/login.service";
import { ToastrService } from "ngx-toastr";
import { JsonPipe } from "@angular/common";

@Component({
  selector: "app-loginc",
  templateUrl: "./loginc.component.html",
  styleUrls: ["./loginc.component.scss"],
})
export class LogincComponent {
  constructor(
    private toastrService: ToastrService,
    private route: Router,
    public Loginservice: LoginService,
    public Globalobjects: GlobalobjectsService
  ) {
    this.countryName = localStorage.getItem("country");
  }

  showPassword: boolean = false;
  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  logoPath: string = "assets/images/placeholder-logo-2.png";

  ngOnInit(): void {
    const settingString = localStorage.getItem("setting") as string;
    if (settingString != "undefined" && settingString != null) {
      let setting = JSON.parse(localStorage.getItem("setting") as string);
      this.logoPath = this.Globalobjects.BUSINESSINFOIMAGEURL + setting.logo;
    }
    if (localStorage.getItem("isLoggedIn") == "true") {
      this.route.navigate(["/index"]);
    }
    if (localStorage.getItem("country") == null) {
      this.route.navigate(["/company"]);
    }
  }

  userName: any;
  userPass: string;
  countryName: string;
  msg: string;
  loginError: string;
  SaleRap: boolean;
  verifyFrom: string;
  PreLoader: boolean = false;

  onSubmit() {
    this.PreLoader = true;
    console.log("Username: " + this.userName);
    console.log("Password: " + this.userPass);

    if (this.SaleRap) {
      const data = {
        countryList_Id: this.countryName,
        loginID: this.userName,
        loginType: 4,
        password: this.userPass,
      };

      this.Loginservice.ValidateSalesRep(data).subscribe(
        (response: any) => {
          console.log("🚀 > LogincComponent > onSubmit > response:", response);
          if (response.apiStatus.isSuccess) {
            this.toastrService.success("Login Successful.");
            this.storeSalesRepData(response);
            this.PreLoader = false;
            this.route.navigate(["/select-customer"]);
          } else {
            this.PreLoader = false;
            this.toastrService.error(response.apiStatus.message);
          }
        },
        (error) => {
          console.log(error);
          this.PreLoader = false;
          this.handleLoginError();
        }
      );
    } else {
      this.verifyFrom = "C";
      const data = {
        countryList_Id: this.countryName,
        loginID: this.userName,
        loginType: 4,
        password: this.userPass,
        verifyFrom: this.verifyFrom,
      };

      this.Loginservice.ValidateUser(data).subscribe(
        (response: any) => {
          console.log("🚀 > LogincComponent > onSubmit > response:", response);

          if (response.apiStatus.isSuccess) {
            this.toastrService.success("Login Successful.");

          
           
             this.storeUserData(response);

            // Fetch items after validation
            this.fetchItems(response.data_Customer);
          } else {
            this.PreLoader = false;
            this.toastrService.error(response.apiStatus.message);
          }
        },
        (error) => {
          console.log(error);
          this.PreLoader = false;
          this.handleLoginError();
        }
      );
    }
  }

  fetchItems(customerData: any) {
    const itemData = {
      countryList_Id: customerData.countryList_Id,
      customerNumber: customerData.customerNumber,
    };

    this.Loginservice.GetItmes(itemData).subscribe((itemsResponse: any) => {
      localStorage["loginresponse"] = JSON.stringify(itemsResponse);
      console.log("Items API Response:", itemsResponse);
      localStorage.setItem("customercredit", itemsResponse.customerInfo.creditLimit);
      localStorage.setItem("customerbalance", itemsResponse.customerInfo.balanceDue);
      localStorage.setItem("pricegroup", itemsResponse.customerInfo.customerPriceGroup);
      localStorage.setItem("shippingcode", itemsResponse.customerInfo.shipToCode);
      // Fetch promotions after fetching items
      this.getPromotionDetails(itemsResponse);
    });
  }

  getPromotionDetails(itemsResponse: any) {
    const data = {
      countryList_Id: localStorage.getItem("country"),
      customerNumber: localStorage.getItem("customerNumber"),
      customerPriceGroup: localStorage.getItem("pricegroup"),
    };
    this.Loginservice.getPromotionDetails(data).subscribe((promoResponse: any) => {
      console.log("Promotion Details API Response:", promoResponse);
      localStorage.setItem("promotiondata", JSON.stringify(promoResponse.data));
      // Map data and finalize processing with promo check
      this.mapAndStoreData(itemsResponse, promoResponse);

      
    });
  }

  mapAndStoreData(itemsResponse: any, promoResponse: any) {
    const apiData = itemsResponse.itemInfo;
    const promotionData = promoResponse.data;

    // Iterate through the items
    for (let i = 0; i < apiData.length; i++) {
      // Add Variants array to each element
      apiData[i].Variants = [
        {
          variant_id: 101,
          id: 1,
          sku: "sku1",
          size: "s",
          color: "yellow",
          image_id: 111,
        },
        // Add other variants as needed
      ];

      // Add Images array to each element
      apiData[i].Images = [
        {
          image_id: 111,
          id: 1,
          alt: "yellow",
          src: "assets/images/product/fashion/39.jpg",
          variant_id: [101, 104],
        },
        {
          image_id: 112,
          id: 1,
          alt: "white",
          src: "assets/images/product/fashion/6.jpg",
          variant_id: [102, 105],
        },
        // Add other images as needed
      ];
    }

   // Check promotions and add promoProduct flag
    promotionData.forEach((promotion: any) => {
      if (promotion.isActive) {
        const currentDate = new Date();
        const startDate = new Date(promotion.date_Start);
        const endDate = new Date(promotion.date_End);

        if (currentDate >= startDate && currentDate <= endDate) {
          if (promotion.isApply_MinQty) {
          promotion.promotion_Items.forEach((promoItem: any) => {
            if (promoItem.isInput) {
            apiData.forEach((item: any) => {
              if (promoItem.itemNumber === item.itemNumber && promoItem.isInput) {
                item.promoProduct = true;
                item.promotionDescription = promotion.promotionDescription;
              }
            });
          }
          });
        }
      }
      }
    });

    const mappedData = apiData.map((item: any) => {
      return {
        id: item.itemNumber,
        title: item.itemName,
        description: "Our Products",
        type: item.itemCategoryCode,
        moq: item.mininumOrderQuantity,
        mo: item.multipleOff,
        brand: "",
        collection: ["new products"],
        category: "",
        price: item.unitPrice,
        sale: item.isDiscount,
        discount: item.discountPercent,
        stock: 10000,
        commonItemNo: item.commonItemNo ? item.commonItemNo : item.itemNumber,
        new: false,
        sortingno: item.portalSortingNo,
        pricepercase: (item.unitPrice && item.noOfRetailsInACase) ? (item.unitPrice / item.noOfRetailsInACase) : 0,
        isPriceGroup: item.isPriceGroup,
        promoProduct: item.promoProduct || false, // Default to false if not set
        promotionDescription: item.promotionDescription || "", // Default to empty string if not set
        tags: [],
        variants: item.Variants.map((variant: any) => {
          return {
            variant_id: variant.variant_id,
            id: variant.id,
            sku: variant.sku,
            size: variant.size,
            color: variant.color,
            image_id: variant.image_id,
          };
        }),
        images: item.Images.map((image: any) => {
          return {
            image_id: image.image_id,
            id: image.id,
            alt: "Item Code: "+item.itemNumber,
            src:
              this.countryName + "_" + item.itemNumber + ".png" &&
              this.countryName + "_" + item.itemNumber + ".png".trim() !== ""
                ? this.countryName + "_" + item.itemNumber + ".png"
                : "placeholder.jpg",
            variant_id: image.variant_id,
          };
        }),
      };
    });

    this.Globalobjects.productdata = mappedData;
    localStorage["rproducts"] = JSON.stringify(mappedData);

    // console.log("Mapped Data:", mappedData);

    // console.log(localStorage.getItem("isLoggedIn"));

    this.PreLoader = false;
      this.route.navigate(["/index"]).then(() => {
        window.location.reload();
      });
  }

  storeSalesRepData(response: any) {
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem("cname", response.salesRepData.salesRepName);
    localStorage.setItem("SalesRepData", JSON.stringify(response.salesRepData));
    localStorage.setItem("isSalesRep", 'true');
    localStorage.setItem("SalesRepCustomersList", JSON.stringify(response.data));
  }

  storeUserData(response: any) {
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem("customercreditlimit", response.data_Customer.isCreditLimitExceedAllowed);
    localStorage.setItem("cname", response.data_Customer.customerName);
    localStorage.setItem("customerName", response.data_Customer.customerName);
    localStorage.setItem("customerNumber", response.data_Customer.customerNumber);
    localStorage.setItem("customer_Id", response.data_Customer.id);
   
  }

  handleLoginError() {
    this.loginError = "Username and password does not match.";
    this.toastrService.error(this.loginError);
  }
}
