import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalobjectsService {

  public customerNumber: string;
  public customerData: any;
  public countryName: string;
  public UserLoginResponse: any;
  public productdata: any;
  public ACData: any;

  
  //public BASEURL: string = 'http://x2xdev.accsoft.com:3004/';
  //public BASEURL: string = 'https://x2xdev.accsoft.com:3001/';
//    public BASEURL: string = 'https://x2xdev.accsoft.com:3007/';

//public BASEURL: string = 'http://44.195.225.219:4203/';

public BASEURL: string = 'https://partner.alsafafoods.com/api/';

    



  //public WebURL: string = 'http://192.168.0.77:4201/#';
  
  // 3007 k lie images or 3001 04 k lie Images
  
  public APIURL = this.BASEURL + "api/";
  public ImageURL: string = this.BASEURL + 'Images/';
   public PDFURL: string = this.BASEURL + 'Tmp/';
  
   // public SLIDERIMAGEURL = this.BASEURL + "wwwroot/SliderPictures/";
  // public PROMOTIONIMAGEURL = this.BASEURL + "wwwroot/uploads/";
  // public COMPANYIMAGEURL = this.BASEURL + "wwwroot/CompanyImages/";
  // public BUSINESSINFOIMAGEURL = this.BASEURL + "wwwroot/BusinessInfo/";

  public SLIDERIMAGEURL = this.BASEURL + "SliderPictures/";
  public PROMOTIONIMAGEURL = this.BASEURL + "uploads/";
  public COMPANYIMAGEURL = this.BASEURL + "CompanyImages/";
  public BUSINESSINFOIMAGEURL = this.BASEURL + "BusinessInfo/";


  public WebURL: string = 'https://alsafaqa.x2xai.com/#/';


  constructor() { }

}
